<template>
	<v-container>
		<please-select-utility>

			<v-toolbar flat v-if="selectedUtility" max-width="450">
				<v-btn
					outlined
					class="mr-4"
					color="grey darken-2"
					@click="setToday"
				>
					Текущ месец
				</v-btn>
				<v-btn
					v-if="month && year"
					fab
					text
					small
					color="grey darken-2"
					@click="prev"
				>
					<v-icon small>mdi-chevron-left</v-icon>
				</v-btn>
				<v-toolbar-title class="mx-2" v-if="month && year">
					{{ month }}.{{ year }}
				</v-toolbar-title>
				<v-btn
					v-if="month && year"
					fab
					text
					small
					color="grey darken-2"
					@click="next"
				>
					<v-icon small>mdi-chevron-right</v-icon>
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn @click="save" color="green" dark :loading="saving">
					Save
				</v-btn>
			</v-toolbar>
			<v-progress-linear indeterminate v-if="selectedUtility && loading" />
			<calendar-grid v-if="month && year && selectedUtility && !loading" :year="year" :month="month" style="max-width: 800px;">
				<template v-slot:day="{day,isoDate}">
					<v-text-field
						:label="day.getDate().toString()"
						:readonly="saving"
						hide-details class="align-right"
						v-model="temperatures[isoDate]"
						@change="dirty = true"
						:background-color="isEmpty(temperatures[isoDate]) ? '' : 'green lighten-4'"
						:color="isEmpty(temperatures[isoDate]) ? '' : 'green darken-4'"
						filled
						:rules="rules"
					/>
				</template>
			</calendar-grid>
			<confirm-dialog
				ref="confirm"
				title=""
				label-confirm="Продължи"
				label-cancel="Откажи"
				color-confirm="red"
			>
				Данните не са записани - ако продължите, ще загубите направените промени
			</confirm-dialog>

		</please-select-utility>
	</v-container>
</template>

<script>
import CalendarGrid from "@/ittijs/components/CalendarGrid.vue";
import Model from './Model';
import ConfirmDialog from '@/ittijs/ConfirmDialog.vue';
import SelectedUtilityMixin from '@/ittijs/SelectedUtilityMixin';
import Validators from '@/ittijs/Validators';
import PleaseSelectUtility from "@/ittijs/components/PleaseSelectUtility";

const imodel = new Model;

export default {
	mixins: [
		SelectedUtilityMixin,
	],
	components: {PleaseSelectUtility, CalendarGrid, ConfirmDialog},
	data(){
		const d = new Date;
		return {
			year: d.getUTCFullYear(),
			month: d.getUTCMonth()+1,
			dirty: false,
			temperatures: {},
			loading: false,
			saving: false,
			rules: [
				Validators.pattern('^-?\\d{1,2}(\\.\\d{1,2})?$', ''),
			],
		};
	},
	computed: {
		yearMonth(){
			return this.year * 12 + this.month;
		},
	},
	watch: {
		yearMonth: {
			immediate: true,
			handler(val) {
				if (val > 0 && this.selectedUtility) {
					this.loadData();
				}
			},
		},
		selectedUtility: {
			immediate: true,
			handler(val){
				if (val && this.yearMonth > 0) {
					this.loadData();
				}
			},
		},
	},
	methods: {
		isEmpty(val){
			if (typeof val === 'undefined') return true;
			if (val === null) return true;
			if (val === '') return true;
			return false;
		},
		confirmIfDirty(callback){
			if (this.dirty) {
				this.$refs.confirm.open()
					.then(res=>{
						if (res) {
							this.dirty = false;
							callback();
						}
					})
				;
				return false;
			}
			return true;
		},
		setToday(){
			if (this.loading || this.saving) return;
			if (this.confirmIfDirty(this.setToday)) {
				const d = new Date;
				this.year = d.getUTCFullYear();
				this.month = d.getUTCMonth()+1;
			}
		},
		prev(){
			if (this.loading || this.saving) return;
			if (this.confirmIfDirty(this.prev)) {
				if (this.month > 1) {
					this.month -= 1;
				}
				else {
					this.month = 12;
					this.year -= 1;
				}
			}
		},
		next(){
			if (this.loading || this.saving) return;
			if (this.confirmIfDirty(this.next)) {
				if (this.month < 12) {
					this.month += 1;
				}
				else {
					this.month = 1;
					this.year += 1;
				}
			}
		},
		async loadData(){
			if (this.loading || this.saving) return;
			this.loading = true;
			this.temperatures = await imodel.getData(this.selectedUtility, this.year, this.month);
			if (Array.isArray(this.temperatures)) this.temperatures = {};
			this.loading = false;
			this.dirty = false;
		},
		async save(){
			if (this.loading || this.saving) return;
			this.saving = true;
			await imodel.saveData(this.selectedUtility, this.year, this.month, this.temperatures);
			this.saving = false;
			this.dirty = false;
		},
	},
}
</script>

<style scoped>
.align-right::v-deep input {
	text-align: end;
	padding-right: 8px;
}
</style>